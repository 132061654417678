import {
  Box,
  Button,
  Flex,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Tooltip,
} from '@chakra-ui/react'
import { colors } from '@hb/shared/constants/colors'
import React from 'react'

function generateCircleSegmentPath(
  radius: number,
  index: number,
  segments: number,
): string {
  // Calculate the angles in radians for the start and end of the segment
  const anglePerSegment = (2 * Math.PI) / segments
  const startAngle = index * anglePerSegment - Math.PI / 2
  const endAngle = startAngle + anglePerSegment

  // Calculate the coordinates for the start and end points of the arc
  const startX = radius * Math.cos(startAngle)
  const startY = radius * Math.sin(startAngle)
  const endX = radius * Math.cos(endAngle)
  const endY = radius * Math.sin(endAngle)

  // The large-arc-flag is 1 if the segment angle is larger than 180 degrees (π radians)
  const largeArcFlag = anglePerSegment > Math.PI ? 1 : 0

  // Move to the starting point, draw the arc, and close the path
  const path = `
    M 0 0
    L ${startX} ${startY}
    A ${radius} ${radius} 0 ${largeArcFlag} 1 ${endX} ${endY}
    Z
  `

  return path.trim()
}

// colors split radially
export const ColorsCircle = ({
  value,
  size = 24,
  withStrikethrough,
}: {
  value: string[]
  size?: number
  withStrikethrough?: boolean
}) => {
  const paths = value.map((color, index) => (value.length === 1 ? (
      <circle key={color} r={21} fill={color} />
  ) : (
      <path
        key={color}
        d={generateCircleSegmentPath(21, index, value.length)}
        fill={color}
      />
  )))

  return (
    <svg
      style={{
        width: `${size}px`,
        filter: 'drop-shadow(1px 1px 3px #000000aa)',
      }}
      viewBox="-25 -25 50 50"
    >
      <circle cx={0} cy={0} r={25} fill="#efefef" />
      {paths.length || !withStrikethrough ? null : (
        <path d="M -20 -20 L 20 20" strokeWidth={3} stroke={colors.red.hex} />
      )}
      {paths}
    </svg>
  )
}

export const ColorsSelect = ({
  value,
  allColors,
  onChange,
}: {
  value: string[]
  onChange: (v: string[]) => void
  allColors: { name: string; hex: string; initials: string }[]
}) => (
  <Flex maxW="138px" flexFlow="row wrap">
    {allColors.map((c) => (
      <Box key={c.hex}>
        <Tooltip placement="top" hasArrow label={c.name}>
          <Button
            aria-label={c.name}
            variant="unstyled"
            margin="2px"
            cursor="pointer"
            background={value.includes(c.hex) ? c.hex : `${c.hex}77`}
            _hover={{ background: c.hex }}
            width="30px"
            minW="0"
            height="30px"
            boxShadow={
              value.includes(c.hex)
                ? '0px 0px 4px #000000'
                : 'inset 0 0 4px #333'
            }
            textShadow="1px 1px 3px #00000077"
            borderRadius={4}
            color="whiteAlpha.900"
            onClick={(e) => {
              e.stopPropagation()
              if (value.includes(c.hex)) {
                onChange(value.filter((v) => v !== c.hex))
              } else {
                onChange([...value, c.hex])
              }
            }}
          >
            {c.initials}
          </Button>
        </Tooltip>
      </Box>
    ))}
  </Flex>
)

export const ColorsSelectPopover = ({ withStrikethrough, ...props }: {
  value: string[]
  onChange: (v: string[]) => void
  allColors: { name: string; hex: string; initials: string }[]
  withStrikethrough?: boolean
}) => (
  <Popover trigger="hover">
    <PopoverTrigger>
      <Box cursor="pointer">
        <ColorsCircle withStrikethrough={withStrikethrough} value={props.value} />
      </Box>
    </PopoverTrigger>
    <PopoverContent width='auto'>
      <ColorsSelect {...props} />
    </PopoverContent>
  </Popover>
)
