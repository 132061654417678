import { Center, Text, VStack } from '@chakra-ui/react'
import { InsuranceCoverageId, NextAction } from '@hb/shared/types'
import { getPlanNextAction, getPlansArray } from '@hb/shared/utils'
import React, { useContext, useMemo } from 'react'
import { UserContext } from '../../../../contexts'
import { Expandable } from '../../../Expandable'
import { Loading } from '../../../Loading'
import { CoverageNextAction } from '../NextAction'

export const PlanNextActions = () => {
  const { selectedAssessment, assessmentId } = useContext(UserContext)

  const { plans, nextActions } = selectedAssessment || {}

  const additionalPlansArr = useMemo(
    () => getPlansArray({ additionalPlans: plans?.additionalPlans }),
    [plans],
  )
  const additionalPlanNextActions = useMemo(
    () => additionalPlansArr.reduce(
      (acc, curr) => {
        acc[curr.id] = getPlanNextAction(nextActions || {}, curr.id)
        return acc
      },
        {} as Record<InsuranceCoverageId, NextAction | undefined>,
    ),
    [nextActions, additionalPlansArr],
  )

  return (
    <Expandable
      borderTop="1px solid #cdcdcd"
      style={{ width: '100%' }}
      initExpanded
      headerProps={{ bg: 'white' }}
      header={() => (
        <Text fontWeight={600} color="#777" w="100%" px={2} py={1}>
          Coverage Next Actions
        </Text>
      )}
    >
      {assessmentId ? (
        <VStack bg="#efefef" spacing={0} w="100%">
          <CoverageNextAction
            assessmentId={assessmentId}
            coverage={plans?.primaryCoverage || undefined}
            id="primaryCoverage"
            nextAction={nextActions?.primaryCoverage}
          />
          <CoverageNextAction
            coverage={plans?.medicaidCoverage || undefined}
            assessmentId={assessmentId}
            id="medicaidCoverage"
            nextAction={nextActions?.medicaidCoverage}
          />
          {additionalPlansArr.map((plan) => (
            <CoverageNextAction
              coverage={plan}
              key={plan.id}
              assessmentId={assessmentId}
              id={plan.id}
              nextAction={additionalPlanNextActions[plan.id]}
            />
          ))}
        </VStack>
      ) : (
        <Center>
          <Loading text="Loading insurance plans..." />
        </Center>
      )}
    </Expandable>
  )
}
