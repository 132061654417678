import { CalendarIcon, EmailIcon } from '@chakra-ui/icons'
import {
  Box,
  BoxProps,
  Button,
  Center,
  Flex,
  Image,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import {
  AssessmentSnippet,
  colors,
  DataColumn,
  Delivery,
  getDateString,
  getPracticeAssessmentCollectionPath,
  InsuranceCoverage,
  InsuranceCoverageId,
  NextAction,
  PracticeAssessmentData,
  stringifyNextAction,
  toSearchString,
  WithId,
  WithMetadata,
} from '@hb/shared'
import React, { useContext, useMemo } from 'react'
import { DataListContext } from '../../../../contexts'
import cashIcon from '../../../../icons/cash.svg'
import { EllipsisText } from '../../../EllipsisText'
import {
  CollectionNextActionContent,
  CoverageNextActionContent,
} from '../../../Users/Profile/NextAction'
import { SortButton } from '../../SortButton'
import { UnreadMessagesBadge } from './UnreadMessageBadge'

type NextActionType = {
  nextActionText?: string
  nextActionDate?: number
  nextAction?: NextAction
}

const getMatchesQuery = (
  nextAction: NextAction | undefined,
  searchQuery: string,
  coverage: InsuranceCoverage | null | undefined,
) => {
  if (!searchQuery) return false
  const stringified = stringifyNextAction(nextAction, coverage)
  const stringifiedQuery = toSearchString(searchQuery)
  return stringified.includes(stringifiedQuery)
}
const CollectionNextActionPopover = ({
  item,
  collection,
  searchQuery,
  label,
}: {
  collection: string
  label: string
  item: WithId
  searchQuery: string
}) => {
  const { nextActionText, nextAction, id } = item || {}
  const { text: baseText } = nextAction || {}
  const matchesQuery = getMatchesQuery(
    nextAction || { text: nextActionText },
    searchQuery,
    undefined,
  )
  const text = nextActionText || baseText
  const bg = matchesQuery ? 'green.400' : 'gray.50'
  const color = matchesQuery ? 'white' : 'blue.600'
  return (
    <Popover strategy="fixed" isLazy>
      {({ isOpen }) => (
        <>
          <PopoverTrigger>
            <Button
              w="100%"
              justifyContent="flex-start"
              size="sm"
              h={8}
              boxShadow={`0 0 4px ${matchesQuery ? colors.green.hex : '#00000044'}`}
              bg={isOpen ? 'blue.600' : bg}
              _hover={{
                bg: isOpen ? 'blue.400' : 'blue.200',
                color: 'white',
              }}
            >
              <Text
                position="relative"
                // width={itemWidth}
                height="18px"
                textAlign="left"
                color={isOpen ? 'white' : color}
                overflow="hidden"
                textOverflow="ellipsis"
                fontWeight={400}
                fontSize="sm"
              >
                {!text || text === 'zzz' ? 'None' : text}
              </Text>
            </Button>
          </PopoverTrigger>
          <PopoverContent px={2} py={1} bg="gray.50" w="auto">
            <PopoverArrow />
            <Text fontWeight={500}>{label} Next Action</Text>
            <Flex w="350px">
              <CollectionNextActionContent
                id={id}
                collection={collection}
                item={item as any}
              />
            </Flex>
            <PopoverCloseButton />
          </PopoverContent>
        </>
      )}
    </Popover>
  )
}

const CoverageNextActionPopover = ({
  nextAction,
  id,
  searchQuery,
  coverage,
  itemId,
}: {
  nextAction: NextAction | undefined
  id: InsuranceCoverageId
  coverage: InsuranceCoverage | undefined | null
  searchQuery: string
  itemId: string
}) => {
  const { text } = nextAction || {}
  const matchesQuery = getMatchesQuery(nextAction, searchQuery, coverage)
  const bg = matchesQuery ? 'green.400' : 'gray.50'
  const color = matchesQuery ? 'white' : 'blue.600'
  const label = useMemo(() => {
    if (id === 'primaryCoverage') return 'Primary'
    if (id === 'medicaidCoverage') return 'Medicaid'
    return 'Potential Plan'
  }, [id])
  return (
    <Popover strategy="fixed" isLazy>
      {({ isOpen }) => (
        <>
          <PopoverTrigger>
            <Button
              w="100%"
              justifyContent="flex-start"
              size="sm"
              h={9}
              position="relative"
              boxShadow={`0 0 4px ${matchesQuery ? colors.green.hex : '#00000022'}`}
              bg={isOpen ? 'blue.600' : bg}
              _hover={{
                bg: isOpen ? 'blue.400' : 'blue.200',
                color: 'white',
              }}
            >
              <Text
                position="relative"
                top="6px"
                // width={itemWidth}
                height="18px"
                textAlign="left"
                color={isOpen ? 'white' : color}
                overflow="hidden"
                textOverflow="ellipsis"
                fontWeight={400}
                fontSize="sm"
              >
                {!text || text === 'zzz' ? 'None' : text}
              </Text>
              <Text
                fontSize="xs"
                textShadow={
                  matchesQuery || isOpen ? '1px 1px 3px #00000055' : 'none'
                }
                color={matchesQuery || isOpen ? 'gray.100' : 'gray.500'}
                opacity={0.7}
                top="2px"
                position="absolute"
              >
                {label.toUpperCase()} -{' '}
                {coverage?.insuranceProvider?.name || 'No Insurer'}
              </Text>
            </Button>
          </PopoverTrigger>
          <PopoverContent px={2} py={1} bg="gray.50" w="auto">
            <PopoverArrow />
            <Text fontWeight={500}>{label} Next Action</Text>
            <Flex w="350px">
              <CoverageNextActionContent
                assessmentId={itemId}
                id={id}
                nextAction={nextAction}
              />
            </Flex>
            <PopoverCloseButton />
          </PopoverContent>
        </>
      )}
    </Popover>
  )
}

export const getRenderItemNextAction = <T extends NextActionType>(
  collection: string,
  label: string,
): DataColumn<NextActionType, T>['Render'] => ({ data, searchQuery }) => (
    <Flex gap={1} w="100%" align="center">
      <Flex flex={1} minW="0">
        <CollectionNextActionPopover
          label={label}
          searchQuery={searchQuery}
          collection={collection}
          item={data}
        />
      </Flex>
    </Flex>
  )

export const RenderCoverageNextActions: DataColumn<
  AssessmentSnippet,
  AssessmentSnippet
>['Render'] = ({ data, searchQuery }) => {
  const {
    nextActions, id, plans, isMedicaidPlan,
  } = data || {}
  return (
    <Flex gap={1} w="100%" align="center">
      <Flex flex={1} minW="0">
        {nextActions?.primaryCoverage || !isMedicaidPlan ? (
          <Flex flex={1} minW="0">
            <CoverageNextActionPopover
              itemId={id}
              id="primaryCoverage"
              coverage={plans?.primaryCoverage}
              searchQuery={searchQuery}
              nextAction={nextActions?.primaryCoverage}
            />
          </Flex>
        ) : null}
        {nextActions?.medicaidCoverage || isMedicaidPlan ? (
          <Flex flex={1} minW="0">
            <CoverageNextActionPopover
              itemId={id}
              coverage={plans?.medicaidCoverage}
              id="medicaidCoverage"
              searchQuery={searchQuery}
              nextAction={nextActions?.medicaidCoverage}
            />
          </Flex>
        ) : null}
        {nextActions?.additionalPlans
          ? Object.entries(nextActions.additionalPlans).map(
            ([nextActionId, nextAction]) => (
                <Flex key={nextActionId} flex={1} minW="0">
                  <CoverageNextActionPopover
                    itemId={id}
                    coverage={plans?.additionalPlans?.[nextActionId]}
                    id={`additionalPlans.${nextActionId}`}
                    searchQuery={searchQuery}
                    nextAction={nextAction}
                  />
                </Flex>
            ),
          )
          : null}
      </Flex>
    </Flex>
  )
}

export const getRenderPracticeAssessmentNextAction = (practiceId: string) => getRenderItemNextAction(
  getPracticeAssessmentCollectionPath(practiceId),
  'Pregnancy',
) as DataColumn<PracticeAssessmentData, PracticeAssessmentData>['Render']

export const insurerColumn: DataColumn<AssessmentSnippet> = {
  title: 'INSURER',
  Header: () => <SortButton sortKey="insuranceCoverage">INSURER</SortButton>,
  sortKey: 'insuranceCoverage',
  Render: ({ data }) => (
    <EllipsisText
      // maxWidth={itemWidth}
      text={
        'insuranceCoverage' in data
        && data?.insuranceCoverage
        && data.insuranceCoverage !== 'zzz'
          ? `${data?.insuranceCoverage}${data.planState ? ` | ${data.planState}` : ''}`
          : 'No coverage'
      }
    />
  ),
}

type DeliveredOnItem = {
  deliveredOn: number | null
  delivery?: WithMetadata<Partial<Delivery>> | null
}
export const getDeliveredOnColumn = <T extends DeliveredOnItem>(): DataColumn<
  DeliveredOnItem,
  T
> => ({
    title: 'Date of Delivery',
    Header: () => (
    <Flex align="center" flexGrow={1}>
      <SortButton sortKey="deliveredOn">
        <CalendarIcon />
        <Text>DELIVERY</Text>
      </SortButton>
    </Flex>
    ),
    width: 106,
    sortKey: 'deliveredOn',
    Render: (props) => {
      let text = 'None'
      if (props.data?.deliveredOn && props.data?.deliveredOn !== Infinity) {
        text = getDateString(props.data.deliveredOn, 'short')
      } else if (
        props.data?.delivery?.isTransfer
      || props.data?.deliveredOn === Infinity
      ) {
        text = 'Transfer'
      }
      return (
      <Stack
        direction="row"
        spacing={2}
        pl={1}
        pr={1}
        width="100%"
        align="center"
      >
        <Text
          opacity={
            props.data?.deliveredOn || props?.data?.delivery?.isTransfer
              ? 1
              : 0.7
          }
          flex={1}
        >
          {text}
        </Text>
      </Stack>
      )
    },
  })

const OverduePaymentIndicator = ({
  hasOverduePayment,
  ...props
}: BoxProps & { hasOverduePayment?: boolean }) => (
  <Box px={1} {...props}>
    <Tooltip
      placement="top"
      hasArrow
      bg={hasOverduePayment ? colors.green.hex : 'yellow.600'}
      label={hasOverduePayment ? 'Overdue payment' : 'No overdue payments'}
    >
      <Image
        opacity={hasOverduePayment ? 1 : 0.7}
        filter={`grayscale(${hasOverduePayment ? 0 : 100}%)`}
        width="20px"
        alt="payment past due"
        src={cashIcon}
      />
    </Tooltip>
  </Box>
)

type HasOverduePaymentItem = {
  hasOverduePayment?: boolean
}

export const getOverduePaymentColumn = <
  T extends HasOverduePaymentItem,
>(): DataColumn<HasOverduePaymentItem, T> => ({
    title: 'Overdue Payment',
    Header: () => {
      const { sortKey } = useContext(DataListContext)
      return (
      <Stack align="center" direction="row" width="100%">
        <SortButton defaultDir="asc" noArrow sortKey="hasOverduePayment">
          <OverduePaymentIndicator
            hasOverduePayment={sortKey === 'hasOverduePayment'}
          />
        </SortButton>
      </Stack>
      )
    },
    // flexProps: { width: '100%' },
    width: 40,
    sortKey: 'hasOverduePayment',
    defaultSortDirection: 'asc',
    Render: ({ data: { hasOverduePayment } }) => (
    <Center w="40px">
      <OverduePaymentIndicator hasOverduePayment={hasOverduePayment} />
    </Center>
    ),
    mobile: {
      width: 130,
      Render: ({ data }) => {
        const { hasOverduePayment } = data || {}
        return (
        <Center
          py={1}
          boxShadow="1px 1px 3px #00000077"
          gap={1}
          bg={hasOverduePayment ? 'yellow.500' : 'gray.100'}
          borderRadius={4}
          w="100%"
          h="28px"
          flex={1}
        >
          <OverduePaymentIndicator hasOverduePayment={hasOverduePayment} />
          <Text
            textShadow={hasOverduePayment ? '1px 1px 3px #00000077' : 'none'}
            lineHeight={1}
            fontFamily="Hero-New"
            fontSize="xs"
            fontWeight={600}
            color={hasOverduePayment ? 'white' : 'gray.500'}
          >
            {hasOverduePayment ? 'OVERDUE' : 'NOT OVERDUE'}
          </Text>
        </Center>
        )
      },
    },
  })

type UnreadThreadMessagesItem = {
  unreadThreadMessages: number
}
export const getUnreadMessagesColumn = <
  Data extends UnreadThreadMessagesItem,
>(): DataColumn<UnreadThreadMessagesItem, Data> => ({
    title: 'Unread Messages',
    Header: () => {
      const { sortKey } = useContext(DataListContext)
      return (
      <SortButton defaultDir="asc" noArrow sortKey="unreadThreadMessages">
        <Tooltip placement="top" hasArrow label="Unread Messages">
          <Box>
            <UnreadMessagesBadge
              isUnread={sortKey === 'unreadThreadMessages'}
            />
          </Box>
        </Tooltip>
      </SortButton>
      )
    },
    defaultSortDirection: 'asc',
    width: 34,
    sortKey: 'unreadThreadMessages',
    Render: ({ data }) => {
      const { unreadThreadMessages } = data || {}
      return (
      <Tooltip
        placement="top"
        hasArrow
        label={
          unreadThreadMessages
            ? `${unreadThreadMessages} unread message${
              unreadThreadMessages > 1 ? 's' : ''
            }`
            : 'No unread messages'
        }
      >
        <Center w="100%" flex={1}>
          <UnreadMessagesBadge isUnread={!!unreadThreadMessages} />
        </Center>
      </Tooltip>
      )
    },
    mobile: {
      width: 100,
      Render: ({ data }) => {
        const { unreadThreadMessages } = data || {}
        return (
        <Tooltip
          placement="top"
          hasArrow
          label={
            unreadThreadMessages
              ? `${unreadThreadMessages} unread message${
                unreadThreadMessages > 1 ? 's' : ''
              }`
              : 'No unread messages'
          }
        >
          <Center
            boxShadow="1px 1px 3px #00000077"
            gap={1}
            bg={unreadThreadMessages ? colors.green.hex : 'gray.100'}
            borderRadius={4}
            w="100%"
            height="28px"
            flex={1}
          >
            <EmailIcon
              height="16px"
              color={!unreadThreadMessages ? 'gray.500' : 'white'}
            />
            <Text
              lineHeight={1}
              fontFamily="Hero-New"
              fontSize="xs"
              textShadow={
                unreadThreadMessages ? '1px 1px 3px #00000077' : 'none'
              }
              fontWeight={600}
              color={unreadThreadMessages ? 'white' : 'gray.500'}
            >
              {unreadThreadMessages} UNREAD
            </Text>
          </Center>
        </Tooltip>
        )
      },
    },
  })
