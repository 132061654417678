import {
  AppName,
  AssessmentSnippet,
  AssessmentStage,
  ASSESSMENT_SNIPPETS,
  Claim,
  CollectionFilter,
  colors,
  DataColumn,
  DataListTab,
  getPracticeAssessmentCollectionPath,
  getPracticeUsersCollectionPath,
  newPatientField,
  newPracticePatientField,
  PracticeAssessmentData,
  PracticeAssessmentStage,
  PresetFilter,
  stateOptions,
  UserRoleItem,
  USER_ROLES,
} from '@hb/shared'
import React, { useContext, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { inviteUser } from '../../backend/auth'
import { ScreenContext, SearchBarProvider, useApp } from '../../contexts'
import { useAppRole } from '../../hooks'
import { DataList } from '../DataList'
import {
  assessmentColumns,
  claimsColumns,
  getProviderAssessmentColumns,
  userColumns,
} from '../DataList/columns'
import { getRowBackground } from '../DataView'
import { UserProfile, UserProfileTabName } from '../Users'
import { PageContainer } from '../Views/PageContainer'

const allAssessmentsTab: DataListTab<
  AssessmentSnippet | PracticeAssessmentData
> = {
  collection: ASSESSMENT_SNIPPETS,
  columns: assessmentColumns,
  archived: false,
  defaultSortKey: 'nextActionDate',
  itemName: 'Assessment',
}

const inquiryAssessmentsTab: DataListTab<
  AssessmentSnippet | PracticeAssessmentData
> = {
  collection: ASSESSMENT_SNIPPETS,
  stage: 'Inquiry',
  archived: false,
  columns: assessmentColumns,
  defaultSortKey: 'nextActionDate',
  itemName: 'Assessment',
}

const authTaskListTab: DataListTab<AssessmentSnippet | PracticeAssessmentData> = {
  collection: ASSESSMENT_SNIPPETS,
  stage: 'Authorization',
  archived: false,
  columns: assessmentColumns,
  defaultSortKey: 'nextActionDate',
  itemName: 'Assessment',
}

const sendClaimListTab: DataListTab<
  AssessmentSnippet | PracticeAssessmentData
> = {
  collection: ASSESSMENT_SNIPPETS,
  stage: 'Send Claim',
  archived: false,
  columns: assessmentColumns,
  defaultSortKey: 'nextActionDate',
  itemName: 'Assessment',
}

// const claimsAssessmentTab: DataListTab<
//   WithId<AssessmentSnippet | PracticeAssessmentData>,
//   AssessmentSortKey> = {
//     collection: ASSESSMENT_SNIPPETS,
//     stage: 'Claims',
//     archived: false,
//     columns: assessmentColumns,
//     defaultSortKey: 'nextActionDate',
//     itemName: 'Assessment',
//   }

const allClaimsTab: DataListTab<Claim> = {
  collection: 'claims',
  archived: false,
  columns: claimsColumns,
  defaultSortKey: 'nextActionDate',
  rightJustifyTab: true,
  itemName: 'Claim',
}

const claimsListTab: DataListTab<Claim> = {
  ...allClaimsTab,
  filters: [['assessmentOnClaimsList', '==', true]],
  rightJustifyTab: false,
}

const completeAssessmentsTab: DataListTab<
  AssessmentSnippet | PracticeAssessmentData
> = {
  collection: ASSESSMENT_SNIPPETS,
  stage: 'Complete',
  archived: false,
  columns: assessmentColumns,
  defaultSortKey: 'nextActionDate',
  itemName: 'Assessment',
}

const archivedAssessmentsTab: DataListTab<
  AssessmentSnippet | PracticeAssessmentData
> = {
  collection: ASSESSMENT_SNIPPETS,
  archived: true,
  columns: assessmentColumns,
  defaultSortKey: 'nextActionDate',
  itemName: 'Assessment',
}

const patientsTab: DataListTab<UserRoleItem> = {
  collection: 'user-roles',
  role: 'user',
  columns: userColumns,
  rightJustifyTab: true,
  archived: false,
  // creation: {
  //   field: newPatientField,
  // },
  defaultSortKey: 'lname',
  itemName: 'Patient',
}

// provider assessment tabs
const getDeliveredBackgroundColor = (index: number) => (index % 2 === 0 ? `${colors.pink.hex}22` : `${colors.pink.hex}45`)
const getTransferredBackgroundColor = (index: number) => (index % 2 === 0 ? `${colors.green.hex}22` : `${colors.green.hex}34`)
const getAssessmentBackgroundColor = (
  item: AssessmentSnippet | PracticeAssessmentData,
  index: number,
) => {
  if (item.deliveredOn && item.deliveredOn !== Infinity) return getDeliveredBackgroundColor(index)
  if (item.delivery?.isTransfer || item.deliveredOn === Infinity) return getTransferredBackgroundColor(index)
  return getRowBackground(index)
}
const getPostpartumBackgroundColor = (item: AssessmentSnippet | PracticeAssessmentData, index: number) => {
  if (item.delivery?.isTransfer || item.deliveredOn === Infinity) return getTransferredBackgroundColor(index)
  return getRowBackground(index)
}

const getPracticeAssessmentTab = (
  practiceId: string,
  stage: AssessmentStage | PracticeAssessmentStage,
  superAdmin: boolean,
): DataListTab<PracticeAssessmentData> => ({
  collection: getPracticeAssessmentCollectionPath(practiceId),
  columns: getProviderAssessmentColumns(stage, practiceId, superAdmin),
  presetFilters: presetAssessmentFilters,
  secondarySortKey: 'nextActionDate',
  archived: false,
  stage: undefined,
  defaultSortKey: 'edd',
  filters: [['stages', 'array-contains', stage]],
  itemName: 'Pregnancy',
  getItemBackgroundColor:
    stage !== 'Postpartum' ? getPostpartumBackgroundColor : getAssessmentBackgroundColor,
})

const getCurrentTab = (practiceId: string, superAdmin: boolean) => getPracticeAssessmentTab(practiceId, 'Current', superAdmin)
const getPostpartumTab = (practiceId: string, superAdmin: boolean) => getPracticeAssessmentTab(practiceId, 'Postpartum', superAdmin)
const getGynTab = (practiceId: string, superAdmin: boolean) => getPracticeAssessmentTab(practiceId, 'Gyn', superAdmin)
const getOtherTab = (practiceId: string, superAdmin: boolean) => getPracticeAssessmentTab(practiceId, 'Other', superAdmin)
const getDischargedTab = (practiceId: string, superAdmin: boolean) => getPracticeAssessmentTab(practiceId, 'Discharged', superAdmin)

const presetAssessmentFilters: Array<PresetFilter> = [
  {
    label: 'Plan Design',
    filters: [
      {
        label: 'Fully-Insured',
        filter: ['planCoverageType', '==', 'Fully Insured'],
      },
      {
        label: 'Self-Funded',
        filter: ['planCoverageType', '==', 'Self-Funded'],
      },
      {
        label: 'Split-Funded',
        filter: ['planCoverageType', '==', 'Split-Funded'],
      },
    ],
  },
  {
    label: 'State of Plan',
    searchable: true,
    filters: stateOptions.map((state) => ({
      label: state.text,
      filter: ['planState', '==', state.id] as CollectionFilter,
    })),
  },
  {
    label: 'Medicaid/Marketplace',
    filters: [
      {
        label: 'Marketplace Plans',
        filter: ['isMarketplacePlan', '==', true],
      },
      {
        label: 'Medicaid Plans',
        filter: ['isMedicaidPlan', '==', true],
      },
    ],
  },
  {
    label: 'Complaints',
    filter: ['hasComplaints', '==', true],
  },
]

const getClaimBackgroundColor = (item: Claim, index: number) => {
  if (item.deliveredOn === Infinity) {
    return getTransferredBackgroundColor(index)
  }
  return getRowBackground(index)
}

const getAssessmentTab = (
  baseTab: DataListTab<AssessmentSnippet | PracticeAssessmentData>,
  midwifeId?: string,
  superAdmin?: boolean,
): DataListTab<AssessmentSnippet | PracticeAssessmentData> => ({
  ...baseTab,
  searchStringPath: 'stringified',
  // itemHeight: midwifeId ? 30 : 36,
  secondarySortKey: 'nextActionDate',
  collection: midwifeId
    ? getPracticeAssessmentCollectionPath(midwifeId)
    : baseTab.collection,
  defaultSortKey: midwifeId ? 'edd' : 'nextActionDate',
  itemHeight: 40,
  presetFilters: presetAssessmentFilters,
  getItemBackgroundColor: getAssessmentBackgroundColor,
  columns: (midwifeId
    ? getProviderAssessmentColumns(baseTab.stage, midwifeId, !!superAdmin)
    : baseTab.columns) as Record<string, DataColumn<AssessmentSnippet | PracticeAssessmentData, AssessmentSnippet | PracticeAssessmentData>>,
})

const getClaimTab = (
  baseTab: DataListTab<Claim>,
  midwifeId?: string,
): DataListTab<Claim> => {
  // TODO: remove hasComplaints global filter
  const filters: Array<CollectionFilter> = midwifeId
    ? [...(baseTab.filters || []), ['midwifeId', '==', midwifeId]]
    : baseTab.filters || []
  return {
    ...baseTab,
    searchStringPath: 'stringified',
    filters,
    secondarySortKey: 'nextActionDate',
    presetFilters: presetAssessmentFilters,
    getItemBackgroundColor: getClaimBackgroundColor,
  }
}

const getPatientTab = (
  appName: AppName,
  baseTab: DataListTab<UserRoleItem>,
  midwifeId?: string,
): DataListTab<UserRoleItem> => ({
  ...baseTab,
  searchStringPath: 'stringified',
  collection: midwifeId
    ? getPracticeUsersCollectionPath(midwifeId)
    : USER_ROLES,
  creation: {
    ...baseTab.creation,
    onCreate: midwifeId
      ? (data) => inviteUser(appName, data, midwifeId)
      : (data) => inviteUser(appName, data),
    field: midwifeId ? newPracticePatientField : newPatientField,
  },
  role: midwifeId ? undefined : 'user',
})

const getTabs = (
  appName: AppName,
  midwifeId?: string,
  superAdmin?: boolean,
): Record<string, DataListTab<any>> => {
  const tabs: Record<string, DataListTab<any>> = {}

  if (!midwifeId) {
    tabs['All Assessments'] = getAssessmentTab(
      allAssessmentsTab,
      midwifeId,
      superAdmin,
    )
    tabs.Inquiry = getAssessmentTab(
      inquiryAssessmentsTab,
      midwifeId,
      superAdmin,
    )
    tabs['Auth / Task List'] = getAssessmentTab(
      authTaskListTab,
      midwifeId,
      superAdmin,
    )
    tabs['Send Claim'] = getAssessmentTab(
      sendClaimListTab,
      midwifeId,
      superAdmin,
    )
    tabs.Claims = getClaimTab(claimsListTab, midwifeId)
    tabs.Complete = getAssessmentTab(
      completeAssessmentsTab,
      midwifeId,
      superAdmin,
    )
  } else {
    tabs['All Pregnancies'] = getAssessmentTab(
      allAssessmentsTab,
      midwifeId,
      !!superAdmin,
    )
    tabs.Inquiry = getPracticeAssessmentTab(midwifeId, 'Inquiry', !!superAdmin)
    tabs.Current = getCurrentTab(midwifeId, !!superAdmin)
    tabs.Postpartum = getPostpartumTab(midwifeId, !!superAdmin)
    tabs.Gyn = getGynTab(midwifeId, !!superAdmin)
    tabs.Other = getOtherTab(midwifeId, !!superAdmin)
    tabs.Discharged = getDischargedTab(midwifeId, !!superAdmin)
    if (superAdmin) {
      tabs.Complete = getPracticeAssessmentTab(
        midwifeId,
        'Complete',
        !!superAdmin,
      )
    }
  }
  tabs.Archived = getAssessmentTab(archivedAssessmentsTab, midwifeId)
  tabs.Patients = getPatientTab(appName, patientsTab, midwifeId)

  return tabs
}

export const AssessmentsList = ({ midwifeId }: { midwifeId?: string }) => {
  const {
    assessmentId, claimId, displayedItem, patientId,
  } = useParams() as {
    assessmentId?: string
    claimId?: string
    patientId?: string
    displayedItem: string
  }
  const role = useAppRole()
  const { appName } = useApp()
  const history = useHistory()
  const tabs = useMemo(
    () => getTabs(appName, midwifeId, role === 'super-admin'),
    [midwifeId, appName, role],
  )
  const basePath = useMemo(() => (midwifeId ? '' : '/admin'), [midwifeId])
  const { contentHeight } = useContext(ScreenContext)
  return (
    <PageContainer fullWidth>
      <SearchBarProvider>
        <DataList
          height={contentHeight - 70}
          rootPath="assessments"
          tabs={tabs}
        />
        <UserProfile
          tab={displayedItem as UserProfileTabName}
          onTabSelect={(tabName) => {
            if (patientId) {
              if (assessmentId) {
                history.push(
                  `${basePath}/patients/${patientId}/${assessmentId}/${tabName}`,
                )
              } else history.push(`${basePath}/patients/${patientId}/${tabName}`)
            } else if (assessmentId) {
              history.push(`${basePath}/assessments/${assessmentId}/${tabName}`)
            } else if (claimId) {
              history.push(`${basePath}/claims/${claimId}/${tabName}`)
            }
          }}
          selectAssessment={(id) => {
            if (id) {
              if (patientId) {
                history.push(
                  `${basePath}/patients/${patientId}/${id}/assessment`,
                )
              } else history.push(`${basePath}/assessments/${id}/assessment`)
            } else if (patientId) history.push(`${basePath}/patients`)
            else history.push(`${basePath}/assessments`)
          }}
          onClose={() => {
            if (patientId) {
              history.push(`${basePath}/patients`)
            } else if (assessmentId) {
              history.push(`${basePath}/assessments`)
            } else {
              history.push(`${basePath}/claims`)
            }
          }}
          userId={patientId}
          assessmentId={assessmentId}
          claimId={claimId}
        />
      </SearchBarProvider>
    </PageContainer>
  )
}
