import { CheckIcon, MinusIcon } from '@chakra-ui/icons'
import {
  Button,
  Center,
  Divider,
  Flex,
  FlexProps,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react'
import {
  AdminAssessmentData,
  AssessmentStage, assessmentStageKeys, colors,
  getDateString,
  PracticeAssessmentData,
  PracticeAssessmentStage,
  practiceAssessmentStageKeys,
} from '@hb/shared'
import React, {
  useCallback, useContext, useMemo, useState,
} from 'react'

import {
  arrayRemove,
  arrayUnion, DocumentData, DocumentReference, updateDoc,
} from 'firebase/firestore'
import { useApp } from '../../../contexts/AppContext'
import { UserContext } from '../../../contexts/UserContext'
import { useMe } from '../../../hooks/backend/useMe'
import { useUpdateDoc } from '../../../hooks/backend/useUpdateDoc'

const AssessmentStatusButton = ({
  mode,
  stages,
  onClick,
  id,
  hovered,
  selectedIds,
  onHover,
  onBlur,
}: {
  mode: 'add' | 'move' | null
  id: AssessmentStage | PracticeAssessmentStage
  stages?: Array<AssessmentStage | PracticeAssessmentStage>
  onClick: () => void
  hovered: boolean
  selectedIds: Array<AssessmentStage | PracticeAssessmentStage>
  onHover: () => void
  onBlur: () => void
}) => {
  const { selected, active } = useMemo(
    () => ({
      selected: selectedIds?.includes(id),
      active: stages?.includes(id),
    }),
    [stages, selectedIds, id],
  )
  const pointerEvents = useMemo(() => {
    if (mode && active) return 'none'
    if (mode) return 'auto'
    return active ? 'auto' : 'none'
  }, [active, mode])
  return (
    <MenuItem
      zIndex={1}
      py={2}
      color="gray.600"
      pointerEvents={pointerEvents}
      onClick={onClick}
      closeOnSelect={false}
      fontWeight={600}
      background={hovered && (mode || active) ? 'gray.50' : 'white'}
      px={4}
      onPointerEnter={onHover}
      onPointerLeave={onBlur}
    >
      {id}
      <Center
        borderRadius={mode === 'move' ? 'full' : 4}
        ml="auto"
        bg={active && mode ? '#efefef' : 'transparent'}
        w={6}
        h={6}
        border={`1px solid ${mode ? '#cdcdcd' : 'transparent'}`}
      >
        {active || selected ? (
          <CheckIcon
            color={
              mode === 'move' && selectedIds.length && !selected
                ? 'gray.300'
                : colors.green.hex
            }
            display={hovered ? 'none' : 'flex'}
            fontSize="0.8rem"
          />
        ) : null}
        {active || selected ? (
          <MinusIcon
            color="red.600"
            display={hovered ? 'flex' : 'none'}
            fontSize="0.8rem"
          />
        ) : null}
      </Center>
    </MenuItem>
  )
}

export const PatientStatusDropdown = (props: FlexProps) => {
  const { selectedAssessment } = useContext(UserContext)
  const { appName } = useApp()
  const me = useMe()
  const {
    archivedOn, adminRef, ref, practiceRef,
  } = selectedAssessment || {}
  const stages = selectedAssessment?.stages as Array<
    AssessmentStage | PracticeAssessmentStage
  >
  const [hovered, setHovered] = useState<
    null | AssessmentStage | PracticeAssessmentStage
  >(null)
  const [mode, setMode] = useState<'add' | 'move' | null>(null)
  const [selectedIds, setSelectedIds] = useState<
    Array<AssessmentStage | PracticeAssessmentStage>
  >([])
  const toast = useToast()
  const [loadingIds, setLoadingIds] = useState<
    Array<AssessmentStage | PracticeAssessmentStage>
  >([])

  const handleCancel = useCallback(() => {
    setMode(null)
    setHovered(null)
    setSelectedIds([])
    setLoadingIds([])
  }, [])

  const handleConfirm = useCallback(async () => {
    const updateRef: DocumentReference<AdminAssessmentData> | DocumentReference<PracticeAssessmentData> | null | undefined = appName === 'app' ? adminRef : practiceRef
    if (!mode || !updateRef) return
    setLoadingIds([...selectedIds])
    try {
      if (mode === 'add') {
        await updateDoc<AdminAssessmentData | PracticeAssessmentData, DocumentData>(updateRef, {
          stages: arrayUnion(...selectedIds),
        })
        toast({ description: 'Added to list(s)', status: 'success' })
      } else {
        await updateDoc<AdminAssessmentData | PracticeAssessmentData, DocumentData>(updateRef, { stages: selectedIds })
        toast({ description: 'Moved to lists(s)', status: 'success' })
      }
    } catch (err) {
      console.error(err)
    }

    setLoadingIds([])
    setSelectedIds([])
    setMode(null)
  }, [mode, selectedIds, adminRef, toast, appName, practiceRef])

  const handleItemClick = useCallback(
    async (id: AssessmentStage | PracticeAssessmentStage) => {
      const updateRef = appName === 'app' ? adminRef : practiceRef
      if (!updateRef) return
      if (!mode) {
        if (stages?.includes(id)) {
          if (stages.length < 2) {
            toast({
              status: 'error',
              description: 'Assessment must be on at least one list',
            })
          } else {
            setLoadingIds([id])
            await updateDoc<AdminAssessmentData | PracticeAssessmentData, DocumentData>(updateRef, { stages: arrayRemove(id) })
            toast({
              status: 'success',
              description: `Removed from ${id}`,
            })
            setLoadingIds([])
          }
        }
        return
      }
      if (selectedIds.includes(id)) {
        setSelectedIds(selectedIds.filter((i) => i !== id))
      } else if (mode === 'add') setSelectedIds([...selectedIds, id])
      else setSelectedIds([id])
    },
    [mode, selectedIds, toast, adminRef, practiceRef, stages, appName],
  )

  const archiveRef = useMemo(() => (appName === 'providers-app' ? practiceRef : ref), [appName, ref, practiceRef])

  const update = useUpdateDoc()
  return (
    <Flex align="center" {...props}>
      {/* <i>[Status Dropdown]</i> */}

      {archivedOn ? (
        <Text
          py="0.2rem"
          px="0.3rem"
          borderRadius={3}
          bg="red.500"
          color="white"
          fontSize="xs"
          mr={2}
          fontWeight="600"
        >
          ARCHIVED {getDateString(archivedOn, 'short')}
        </Text>
      ) : null}
      <Menu>
        <MenuButton
          as={Button}
          variant="outline"
          color="blackAlpha.700"
          bg="white"
          px={4}
          py={1}
          borderRadius="full"
          // boxShadow='0 0 4px rgba(0,0,0,0.5)'
          shadow="md"
        >
          <Flex align="center" justify="center">
            <Text
              fontWeight={500}
              fontFamily="Hero-New"
              fontSize="sm"
              lineHeight={1}
            >
              {stages?.join(', ') || 'Not on any lists'}
            </Text>
          </Flex>
        </MenuButton>
        <Portal>
          <MenuList border="1px solid #cdcdcd" boxShadow="md" w="300px" py={0}>
            <VStack w="100%" divider={<Divider />} spacing={0}>
              <HStack
                w="100%"
                py={2}
                bg="gray.100"
                px={2}
                justify="space-between"
              >
                {mode ? (
                  <>
                    <Button
                      isDisabled={!!loadingIds.length}
                      onClick={handleCancel}
                      bg="white"
                      size="sm"
                      variant="outline"
                    >
                      Cancel
                    </Button>
                    <Button
                      isLoading={!!loadingIds.length}
                      onClick={handleConfirm}
                      bg={colors.green.hex}
                      color="white"
                      size="sm"
                      variant="outline"
                    >
                      Save
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      onClick={() => setMode('add')}
                      bg={colors.green.hex}
                      size="sm"
                      color="white"
                    >
                      Add to list(s)
                    </Button>
                    <Button
                      bg={colors.green.hex}
                      onClick={() => {
                        setMode('move')
                        setSelectedIds(stages || [])
                      }}
                      color="white"
                      size="sm"
                    >
                      Move to list
                    </Button>
                  </>
                )}
              </HStack>
              {(appName === 'app'
                ? assessmentStageKeys
                : practiceAssessmentStageKeys
              ).map((k) => (
                <AssessmentStatusButton
                  key={k}
                  id={k}
                  onBlur={() => setHovered(null)}
                  mode={mode}
                  hovered={hovered === k}
                  selectedIds={selectedIds}
                  onClick={() => handleItemClick(k)}
                  onHover={() => setHovered(k)}
                  stages={stages}
                />
              ))}
              <MenuItem
                fontSize="xs"
                fontWeight={500}
                closeOnSelect={false}
                px={4}
                py={3}
                bg="#efefef"
                color={'red.600'}
                onClick={() => {
                  if (!archiveRef) return { error: 'internal error' }
                  if (!me) return { error: 'no user' }
                  return update(archiveRef, '', {
                    archivedOn: archivedOn ? null : Date.now(),
                    archivedBy: archivedOn ? null : me.uid,
                  })
                }}
              >
                {archivedOn
                  ? `ARCHIVED ${getDateString(archivedOn!, 'short')}`
                  : 'ARCHIVE'}
                {archivedOn ? <CheckIcon ml="auto" /> : null}
              </MenuItem>
            </VStack>
          </MenuList>
        </Portal>
      </Menu>
    </Flex>
  )
}
