import {
  Button, Flex,
  FlexProps,
  Text,
} from '@chakra-ui/react'
import {
  Assessment, InsuranceCoverage,
  InsuranceCoverageRequest,
  InsurancePlans,
  isInsuranceCoverageRequest, PopulatedAssessmentInterface, WithId,
} from '@hb/shared/types'
import {
  objectToArray,
} from '@hb/shared/utils'
import React, {
  useCallback, useMemo, useState,
} from 'react'
import { useMobileLayout } from '../../hooks/useMobileLayout'
import { CoverageRequestView } from './Coverage/CoverageRequestView'
import { CoverageStage } from './Coverage/CoverageStage'
import { NewPlanModal } from './Coverage/NewPlanModal'

const NewPlanButton = ({ onClick }: { onClick: () => void }) => (
  <Flex w="100%" position="relative">
    <Button onClick={onClick} py={1} variant="link" w="100%" size="sm">
      + New Plan
    </Button>
  </Flex>
)

export const AdditionalPlansView = ({
  assessment,
  assessmentId,
  noHeader,
  openPlanId,
  onOpenPlan,
  adminView,
  preview,
  requests,
  legacySecondaryPlan,
  additionalPlans,
  ...flexProps
}: FlexProps & {
  assessment: Assessment | PopulatedAssessmentInterface
  additionalPlans?: InsurancePlans['additionalPlans']
  legacySecondaryPlan?: InsuranceCoverage
  requests?: InsurancePlans['requests']
  assessmentId: string
  noHeader?: boolean
  preview?: boolean
  onOpenPlan?: (id: string | null) => void
  openPlanId?: string | null
  adminView?: boolean
}) => {
  const nextActions = useMemo(() => {
    if (!adminView) return undefined
    return (assessment as PopulatedAssessmentInterface).nextActions || {}
  }, [adminView, assessment])

  const [selectedPlanId, setSelectedPlanId] = useState<string | null>(null)
  const planId = useMemo(
    () => (openPlanId === undefined ? selectedPlanId : openPlanId),
    [openPlanId, selectedPlanId],
  )
  const onOpen = useCallback(
    (id: string | null) => {
      const openFunc = onOpenPlan === undefined ? setSelectedPlanId : onOpenPlan
      openFunc(openPlanId === id ? null : id)
    },
    [openPlanId, onOpenPlan],
  )
  const [newPlanOpen, setNewPlanOpen] = useState(false)

  const sortedAdditionalPlans = useMemo<
    WithId<InsuranceCoverage | InsuranceCoverageRequest>[]
  >(() => objectToArray({ ...additionalPlans, ...requests }), [additionalPlans, requests])

  const { ref, width } = useMobileLayout(600)

  return (
    <>
      <Flex ref={ref} w='100%' gap={preview ? 1 : 2} flexFlow="column" px={preview ? 1 : 3} py={preview ? 0 : 1} {...flexProps}>
        {!adminView && noHeader ? null : (
          <Flex w="100%" align="center">
            {noHeader ? null : (
              <Text
                fontSize={preview ? 'md' : 'lg'}
                lineHeight={1}
                fontWeight={600}
                color="gray.600"
              >
                Potential Plans
              </Text>
            )}
          </Flex>
        )}
        {
          legacySecondaryPlan ? (
            <CoverageStage
              width={width}
              id={legacySecondaryPlan.id}
              assessmentId={assessmentId}
              nextAction={nextActions?.additionalPlans?.[legacySecondaryPlan.id]}
              coverage={legacySecondaryPlan}
              isOpen={planId === legacySecondaryPlan.id}
              onOpenToggle={() => onOpen(planId === legacySecondaryPlan.id ? null : legacySecondaryPlan.id)}
              adminView={adminView}
            />
          ) : null
        }
        {sortedAdditionalPlans.length ? (
          sortedAdditionalPlans.map((plan) => (isInsuranceCoverageRequest(plan) ? (
              <CoverageRequestView
                assessment={assessment}
                assessmentId={assessmentId}
                adminView={adminView}
                key={plan.id}
                request={plan}
              />
          ) : (
              <CoverageStage
                width={width}
                key={plan.id}
                id={plan.id}
                assessmentId={assessmentId}
                nextAction={nextActions?.additionalPlans?.[plan.id]}
                coverage={plan}
                isOpen={planId === plan.id}
                onOpenToggle={() => onOpen(planId === plan.id ? null : plan.id)}
                adminView={adminView}
              />
          )))
        ) : (
          <Text
            px={3}
            py={1}
            bg="gray.50"
            fontStyle="italic"
            border="1px solid #cdcdcd"
            borderRadius={6}
          >
            No potential plans
          </Text>
        )}
        {!adminView ? null : (
          <NewPlanButton onClick={() => setNewPlanOpen(true)} />
        )}
      </Flex>
      {adminView && newPlanOpen ? (
        <NewPlanModal onClose={() => setNewPlanOpen(false)} />
      ) : null}
    </>
  )
}
