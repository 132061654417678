import { EmailIcon } from '@chakra-ui/icons'
import { colors } from '@hb/shared/constants'
import React from 'react'
import './styles.css'

export const UnreadMessagesBadge = ({ isUnread }: { isUnread: boolean }) => (
    <EmailIcon
      className={isUnread ? 'unread-message-badge' : 'read-message-badge'}
      width="28px"
      background='transparent'
      height="18px"
      color={isUnread ? colors.green.hex : 'gray.400'}
    />
)
